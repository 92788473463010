import _ from "lodash";
export default class Breadcrumb {
  constructor() {
    this.items = [];
  }
  add(item) {
    this.items.push(item);
  }
  addForOperationAdd(params) {
    const { model } = params;
    this.addForOperationBrowse(params);
    this.add({
      text: `New ${model.name.singular}`,
      to: {
        name: "PageServiceModelAdd",
        params: {
          serviceKey: model.serviceKey,
          modelKey: model.key,
        },
      },
      exact: true,
    });
  }
  addForOperationBrowse(params) {
    const { model, services } = params;
    this.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.add({
      text: services
        ? _.find(services, { key: model.serviceKey }).name
        : "Current Service",
      to: {
        name: "PageServiceRedirect",
        params: { serviceKey: model.serviceKey },
      },
      exact: true,
    });
    this.add({
      text: model.name.plural,
      to: {
        name: "PageServiceModelBrowse",
        params: { serviceKey: model.serviceKey, modelKey: model.key },
      },
      exact: true,
    });
  }
  addForOperationDelete(params) {
    const { model, modelId } = params;
    this.addForOperationRead(params);
    this.add({
      text: `Delete`,
      to: {
        name: "PageServiceModelDelete",
        params: {
          serviceKey: model.serviceKey,
          modelKey: model.key,
          modelId: modelId,
        },
      },
      exact: true,
    });
  }
  addForOperationDeleteHard(params) {
    const { model, modelId } = params;
    this.addForOperationRead(params);
    this.add({
      text: `Delete`,
      to: {
        name: "PageServiceModelDeleteHard",
        params: {
          serviceKey: model.serviceKey,
          modelKey: model.key,
          modelId: modelId,
        },
      },
      exact: true,
    });
  }
  addForOperationEdit(params) {
    const { model, modelId } = params;
    this.addForOperationRead(params);
    this.add({
      text: `Edit`,
      to: {
        name: "PageServiceModelEdit",
        params: {
          serviceKey: model.serviceKey,
          modelKey: model.key,
          modelId: modelId,
        },
      },
      exact: true,
    });
  }
  addForOperationRead(params) {
    const { model, modelId } = params;
    this.addForOperationBrowse(params);
    this.add({
      text: `${model.name.singular} ${modelId}`,
      to: {
        name: "PageServiceModelRead",
        params: {
          serviceKey: model.serviceKey,
          modelKey: model.key,
          modelId: modelId,
        },
      },
      exact: true,
    });
  }
  addForOperationChildBrowse(params) {
    const { services, parentModel, parentModelId, childModel } = params;
    this.addForOperationRead({
			services: services,
      model: parentModel,
      modelId: parentModelId,
    });
    this.add({
      text: `${
        childModel.name[parentModel.key]
          ? childModel.name[parentModel.key].plural
          : childModel.name.plural
      }`,
      to: {
        name: "PageServiceModelRead",
        query: {
          tab: childModel.key,
        },
        params: {
          serviceKey: parentModel.serviceKey,
          modelKey: parentModel.key,
          modelId: parentModelId,
        },
      },
      exact: true,
    });
  }
  addForOperationChildAdd(params) {
    const { parentModel, parentModelId, childModel } = params;
    this.addForOperationChildBrowse(params);
    this.add({
      text: `New`,
      to: {
        name: "PageServiceModelChildAdd",
        params: {
          serviceKey: parentModel.serviceKey,
          parentModelKey: parentModel.key,
          parentModelId: parentModelId,
          childModelKey: childModel.key,
        },
      },
      exact: true,
    });
  }
  addForOperationChildDetach(params) {
    const {
      parentModel,
      parentModelId,
      childModel,
      intermediateModel,
      intermediateModelId,
    } = params;
    this.addForOperationChildBrowse(params);
    this.add({
      text: `Detach ${childModel.name.singular} From ${parentModel.name.singular}`,
      to: {
        name: "PageServiceModelChildDetach",
        params: {
          serviceKey: parentModel.serviceKey,
          parentModelKey: parentModel.key,
          parentModelId: parentModelId,
          childModelKey: childModel.key,
          intermediateModelKey: intermediateModel.key,
          intermediateModelId: intermediateModelId,
        },
      },
      exact: true,
    });
  }
}
