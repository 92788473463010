<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Mode"/>
    <!--EOC-->
    <v-alert v-if="isDebugging || isDeveloping" color="warning" dark>WARNING: You have activated <b v-if="isDebugging">debug mode</b><span v-if="isDebugging && isDeveloping"> and </span><b v-if="isDeveloping">develop mode</b> for your current browser.</v-alert>
    <v-card>
      <v-card-text>
        <b>Debug Mode</b>
        <p>To access features for testing.</p>
        <v-switch
          v-model="isDebugging"
          inset
          :label="modeDebugText"
          @change="changeModeDebug"
        ></v-switch>
        <v-divider/>
        <b>Develop Mode</b>
        <p>To see pages/components that is under development.</p>
        <v-switch
          v-model="isDeveloping"
          inset
          :label="modeDevelopText"
          @change="changeModeDevelop"
        ></v-switch>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    modeDevelopText() {
      var state = "OFF"
      if (this.isDevelopping) state = "ON"
      return state;
    },
    modeDebugText() {
      var state = "OFF"
      if (this.isDebugging) state = "ON"
      return state;
    },
    ...mapState({
      debugMode: (state) => state.debugMode.data,
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    isDebugging: false,
    isDeveloping: false,
  }),
  watch: {
    isDeveloping(newVal) {
      if(newVal) {
        this.$store.dispatch("startDevelop");
      }
      else {
        this.$store.dispatch("stopDevelop");
      }
      
    },
    isDebugging(newVal) {
      if(newVal) {
        this.$store.dispatch("startDebug");
      }
      else {
        this.$store.dispatch("stopDebug");
      }
    }
  },
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Testing",
      to: { name: "PageServiceDashboard", params: { serviceKey: "testing" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Mode",
      to: { name: "PageServiceTestingMode" },
      exact: true,
    });
    //EOC
    //BOC
    this.isDebugging = this.debugMode.isDebugging
    this.isDeveloping = this.debugMode.isDeveloping
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    changeModeDebug() {
      this.$store.dispatch("showMessage", `Debug mode ${(this.isDebugging) ? 'ON' : 'OFF'}`);
    },
    changeModeDevelop() {
      this.$store.dispatch("showMessage", `Develop mode ${(this.isDeveloping) ? 'ON' : 'OFF'}`);
    },
  },
};
</script>